import React from "react"
import PropTypes from "prop-types"
import SEO from "../components/seo"

import { graphql } from "gatsby"
import { LocaleContext } from "../components/layout"
import DetailView from "../components/Templates/detailView"
import KoepfeContent from "../components/Koepfe/koepfeContent"
import IS_CORPORATE from "../config/siteConfig"

const Koepfe = ({
  data: { content: contentCorporate, contentVentures, koepfe },
  pageContext: { locale },
  location,
}) => {
  const lang = React.useContext(LocaleContext)
  //const i18n = lang.i18n[lang.locale]
  const content = IS_CORPORATE ? contentCorporate : contentVentures

  return (
    <>
      <SEO
        title={content.data.koepfe_title.text || "Köpfe"}
        description={content.data.seo_koepfe_beschreibung.text}
        keywords={
          content.data.seo_koepfe_keywords.text
            ? content.data.seo_koepfe_keywords.text.split(", ")
            : [`Mitarbeiter`, `Köpfe`, `Vitae`]
        }
      />

      <DetailView img={content.data.koepfe_bild}>
        <KoepfeContent
          onlyGrid={true}
          title={content.data.koepfe_title.text}
          text={content.data.koepfe_text.html}
          data={koepfe}
        />
      </DetailView>
    </>
  )
}

export default Koepfe

Koepfe.propTypes = {
  data: PropTypes.shape({
    koepfe: PropTypes.object,
    content: PropTypes.object.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}

export const pageQuery = graphql`
  query KoepfePageQuery($locale: String!) {
    content: prismicHomepage(lang: { eq: $locale }) {
      data {
        koepfe_text {
          html
        }
        koepfe_title {
          text
        }
        seo_koepfe_beschreibung {
          text
        }
        seo_koepfe_keywords {
          text
        }
        koepfe_bild {
          alt
          url
          localFile {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    contentVentures: prismicHomepageVentures(lang: { eq: $locale }) {
      data {
        koepfe_text {
          html
        }
        koepfe_title {
          text
        }
        seo_koepfe_beschreibung {
          text
        }
        seo_koepfe_keywords {
          text
        }
        koepfe_bild {
          alt
          url
          localFile {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    koepfe: allPrismicKopf(
      filter: { lang: { eq: $locale } }
      sort: { fields: [uid], order: ASC }
    ) {
      edges {
        node {
          uid
          data {
            title {
              text
            }
            position {
              text
            }
            content {
              html
            }
            show_on_ventures_page
            logo {
              alt
              url
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1200, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
